import React, { useState } from 'react';
import { HiArrowNarrowDown } from 'react-icons/hi';
import { Link } from "react-scroll"; 

const Skills = () => {
  const [tooltip, setTooltip] = useState({ visible: false, content: '', position: {} });

  const skills = [
    { title: 'SHOPIFY + LIQUID', description: 'Build customized, powerful e-commerce stores using Shopify and Liquid.' },
    { title: 'WORDPRESS + PHP', description: 'Create dynamic websites and blogs using WordPress and PHP.' },
    { title: 'HTML', description: 'Structure web content using semantic, clean HTML.' },
    { title: 'JAVASCRIPT', description: 'Add interactivity to web pages using JavaScript.' },
    { title: 'REACT', description: 'Build dynamic user interfaces using React. This site is built in React!' },
    { title: 'GITHUB', description: 'Manage, backup and share code using GitHub.' },
    { title: 'NODE JS', description: 'Build server-side applications using Node.js.' },
    { title: 'SASS/SCSS/TAILWIND', description: 'Write maintainable and reusable styles with SASS/SCSS or Tailwind CSS.' }
  ];

  const showTooltip = (content) => {
    setTooltip({ visible: true, content });
  };

  const hideTooltip = () => {
    setTooltip({ visible: false, content: '' });
  };

  return (
    <div name='skills' className='skills w-full h-screen text-gray-300 relative'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='w-full flex justify-center items-center flex-col mb-7'>
          <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-center'>Skills / Technologies</p>
          <p className='py-4 text-2xl text-center'>Below are just a few of the major technologies and languages that I have learned throughout my years. You can hover over each for more information!</p>
        </div> 
        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          {skills.map((skill, index) => (
            <div
              key={index}
              className='relative shadow-md shadow-[#040c16] hover:cursor-crosshair hover:scale-110 duration-500 group'
              onMouseEnter={() => showTooltip(skill.description)}
              onMouseLeave={hideTooltip}
            >
            <p className='my-4'>{skill.title}</p>
              {tooltip.visible && tooltip.content === skill.description && (
                <div className='max-w-[230px] absolute bottom-full mb-2 w-max bg-gray-700 text-white text-sm p-2 rounded shadow-lg'
                     dangerouslySetInnerHTML={{ __html: tooltip.content }}>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='w-full flex justify-center items-center flex-col mb-7'>
        <div className='py-4 text-sm text-center max-w-[900px] mx-auto'>
        <ul className='skills-container flex flex-wrap justify-center list-none p-0 m-0'>
          <li className='inline'>Cross-Browser Compatibility & Responsiveness</li>
          &#x2022; <li className='inline'>Accessibility & Best Practices (ADA)</li>
          &#x2022; <li className='inline'>UI/UX</li>
          &#x2022; <li className='inline'>Analytics & Reporting</li>
          &#x2022; <li className='inline'>APIs & Framework Integrations</li>
          &#x2022; <li className='inline'>Agile</li>
          &#x2022; <li className='inline'>Adobe Suite</li>
          &#x2022; <li className='inline'>Figma</li>
          &#x2022; <li className='inline'>JIRA</li>
          &#x2022; <li className='inline'>Quality Assurance</li>
          &#x2022; <li className='inline'>Monday Work Management</li>
          &#x2022; <li className='inline'>Microsoft Suite</li>
        </ul>
      </div>
        </div> 
        <div className='w-full flex justify-center items-center flex-col mb-7'>
          <div className="group">
            <Link
              to="work"
              smooth
              duration={500}
              className="button-down text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer mt-8 group-hover:scale-105 duration-300"
            >
              Check Out My Work
              <span className="ml-3 group-hover:rotate-90 duration-300">
                <HiArrowNarrowDown size={25} />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
