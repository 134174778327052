import React from 'react';
import { HiArrowNarrowDown } from 'react-icons/hi';
import { Link } from "react-scroll";
import abImage from '../assets/ab-desktop-full.png';
import abHoverImage from '../assets/ab-desktop-full.png';
import usrxImage from '../assets/usrx-desktop-full.png';
import usrxHoverImage from '../assets/usrx-desktop-full.png';
import imhImage from '../assets/imh-desktop-full.png';
import imhHoverImage from '../assets/imh-desktop-full.png';
import boldgridImage from '../assets/bg-desktop-full.png';
import boldgridHoverImage from '../assets/bg-desktop-full.png';
const abLink = 'https://anisabeauty.com/';
const usrxLink = 'https://urbanskinrx.com/';
const imhLink = 'https://inmotionhosting.com/';
const bgLink = 'https://boldgrid.com/';

const works = [
  {
    title: 'Anisa Companies',
    description: 'Multiple brands specializing in cosmetic brushes and tools',
    bgImage: abImage, 
    hoverImage: abHoverImage,
    link: abLink
  },
  {
    title: 'Urban Skin Rx',
    description: 'Personal Skin Care Brand',
    bgImage: usrxImage,
    hoverImage: usrxHoverImage,
    link: usrxLink
  },
  {
    title: 'InMotion Hosting',
    description: 'Server & Site Hosting',
    bgImage: imhImage, 
    hoverImage: imhHoverImage,
    link: imhLink
  },
  {
    title: 'BoldGrid',
    description: 'WordPress Site Themes & Plugin Services',
    bgImage: boldgridImage, 
    hoverImage: boldgridHoverImage,
    link: bgLink
  }
];

const Works = () => {
  return (
    <div name='work' className='works w-full h-screen text-gray-300'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8 pt-8 w-full flex justify-center items-center flex-col'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-cyan-500'>
            Recent Work
          </p>
        </div>
        {/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-2 gap-6'>
          {works.map((work, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url(${work.bgImage})` }}
              className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div bg-cover bg-center relative'
              data-hover-image={work.hoverImage}
            >
              {/* Hover Effects */}
              <div className='opacity-0 group-hover:opacity-100 duration-300 flex flex-col p-4 w-[70%] absolute bottom-4 right-4 rounded-md' style={{ backgroundColor: 'rgba(10,25,47,0.9)' }}>
                <span className='text-center text-lg font-bold text-white tracking-wider'>
                  {work.title}
                </span>
                <p className='text-center'>{work.description}</p>
                <div className='pt-8 text-center'>
                  <a href={work.link} target='_blank' rel="noreferrer">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg hover:scale-105 duration-300'>
                      Brand Website
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center items-center flex-col mb-7">
          <div className="group">
            <Link
              to="contact"
              smooth
              duration={500}
              className="button-down text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer mt-8 group-hover:scale-105 duration-300"
            >
              Contact Me
              <span className="ml-3 group-hover:rotate-90 duration-300">
                <HiArrowNarrowDown size={25} />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
