import React from "react";
import { HiArrowNarrowDown } from 'react-icons/hi';
import { Link } from "react-scroll"; 

const About = () => { 
  return (
    <div
      name="about"
      id="about"
      className="w-full h-screen text-gray-300"
    >
      <div className="flex flex-col justify-center items-center md:w-full h-full">
        <div className="py-16 rounded-md bg-cyan-800 flex flex-col justify-center items-center md:w-4/6 max-w-[1200px]">
          <div className="max-w-[1000px] w-full grid md:grid-cols-2 gap-8 mb-4">
            <div className="sm:text-right pb-8 pl-4">
              <p className="text-4xl font-bold inline border-b-4 border-cyan-500">
                About Me
              </p>
            </div>
            <div></div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="sm:text-right text-4xl font-bold">
              <p>
                Hi. I'm Justin, <br />I look forward to working with you!
              </p>
            </div>
            <div>
              <p>
                {" "}
                I am a veteran of the United States Navy and a lover of all things digital. E-Commerce is a vast and intricate puzzle that doesn't always quite fit together. My goal is to figure out how to make this happen and to execute. Every project is a new and unique challenge that I look forward to jumping into every time. Check out my skills and work below, and see why I'm the right fit for your next big(or little) project!
              </p>
              <div className="group">
                <Link
                  to="skills"
                  smooth
                  duration={500}
                  className="button-down text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer mt-8 group-hover:scale-105 duration-300"
                >
                  Skills & Technologies
                  <span className="ml-3 group-hover:rotate-90 duration-300">
                    <HiArrowNarrowDown size={25} />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
