import React, { useState } from 'react'; 
import {
  FaBars,
  FaTimes,
  // FaGithub,
  FaLinkedin,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='navbar-container fixed w-full h-[80px] flex md:justify-center items-center px-4 text-gray-300'>
      {/* menu */}
      <ul className='hidden md:flex gap-x-8 bg-[#1e293b] rounded-lg py-2 px-4'>
        <li className='relative group'>
          <Link to='home' smooth={true} duration={500} className='relative'>
            Home
            <span className='absolute left-0 bottom-0 w-0 h-[2px] bg-cyan-800 transition-all duration-500 group-hover:w-full'></span>
          </Link>
        </li>
        <li className='relative group'>
          <Link to='about' smooth={true} duration={500} className='relative'>
            About
            <span className='absolute left-0 bottom-0 w-0 h-[2px] bg-cyan-800 transition-all duration-500 group-hover:w-full'></span>
          </Link>
        </li>
        <li className='relative group'>
          <Link to='skills' smooth={true} duration={500} className='relative'>
            Skills
            <span className='absolute left-0 bottom-0 w-0 h-[2px] bg-cyan-800 transition-all duration-500 group-hover:w-full'></span>
          </Link>
        </li>
        <li className='relative group'>
          <Link to='work' smooth={true} duration={500} className='relative'>
            Work
            <span className='absolute left-0 bottom-0 w-0 h-[2px] bg-cyan-800 transition-all duration-500 group-hover:w-full'></span>
          </Link>
        </li>
        <li className='relative group'>
          <Link to='contact' smooth={true} duration={500} className='relative'>
            Contact
            <span className='absolute left-0 bottom-0 w-0 h-[2px] bg-cyan-800 transition-all duration-500 group-hover:w-full'></span>
          </Link>
        </li>
      </ul>
      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center bg-[#0a192f] '
        }
      >
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='work' smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
      {/* Social icons */}
      <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-cyan-800'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.linkedin.com/in/justin-phillips-developer/'
              target='_blank'
              rel='noreferrer'
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
{/*           <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-cyan-800'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://github.com/AnalogVikingr'
              target='_blank'
              rel='noreferrer'
            >
              Github <FaGithub size={30} />
            </a>
          </li> */}
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='mailto:jmphillipsofficial@gmail.com'
              target='_blank'
              rel='noreferrer'
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='/Justin_Phillips_Resume.pdf'
              target='_blank'
              rel='noreferrer'
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
