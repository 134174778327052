import React from 'react';
import { HiArrowNarrowDown } from 'react-icons/hi';
import me from '../assets/jmp-headshot.jpg';
import { Link } from "react-scroll"; 

const Home = () => {
  return (
    <div name="home" className="h-screen w-full home">
      <div className="main max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4">
        <div className="flex flex-col items-center w-full mb-8">
          <h2 className="main-heading text-4xl lg:text-7xl font-bold text-cyan-800 text-center">
            Web Development.<br />
            E-Commerce Solutions.<br />
            Analytics & Data Assistance.
          </h2>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between w-full">
          <div className="flex flex-col justify-center h-full w-full md:w-1/2 md:pr-8">
            <p className="text-gray-500 py-4 max-w-md mx-auto md:mx-0 text-2xl">
              I am a polymath of E-Commerce but a Developer at heart. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and setting businesses up for success. I am always looking for new challenges and opportunities to grow in whatever I do.
            </p>
            <div className="group mx-auto md:mx-0">
              <Link
                to="about"
                smooth
                duration={500}
                className="button-down text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer group-hover:scale-105 duration-300"
              >
                About Me
                <span className="ml-3 group-hover:rotate-90 duration-300">
                  <HiArrowNarrowDown size={25} />
                </span>
              </Link>
            </div>
          </div>
          <div className="mt-8 md:mt-0 w-full md:w-1/2">
            <img
              src={me}
              alt="my profile"
              className="main-photo rounded-2xl mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
