// Contact.jsx
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const formRef = useRef();
  const recaptchaRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    if (recaptchaValue) {
      emailjs.sendForm('service_r4sze9t', 'template_l8yhetx', formRef.current, 'o2NpeFTdisH6JEEjE')
        .then((result) => {
          console.log('Email sent:', result.text);
          setIsSubmitted(true); // Set the state to true on successful submission
        }, (error) => {
          console.log('Email error:', error.text);
        });
      recaptchaRef.current.reset(); // Reset the reCAPTCHA after execution
    } else {
      console.log('Please complete the reCAPTCHA');
    }
  };

  return (
    <div name='contact' className='contact max-w-screen-lg mx-auto flex flex-col items-center justify-center h-screen px-4'>
      {isSubmitted ? (
        <div className='flex flex-col justify-center items-center text-gray-300'>
          <h2 className='text-4xl font-bold'>Thank You!</h2>
          <p className='mt-4'>Your message has been sent successfully. I will get back to you as soon as possible!</p>
        </div>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit} className='flex flex-col max-w-[600px] w-full'>
          <div className='pb-8 flex flex-col justify-center w-full h-full items-center'>
            <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-gray-300'>Contact Me</p>
            <p className='text-gray-300 py-4'>Send me a message regarding your next project!</p>
          </div>
          <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name='name' required />
          <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='email' required />
          <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Message' required></textarea>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lf4fh8qAAAAADes82_q1vp7sUwwduVR-t7Xbyxd"
            size="invisible"
          />
          <button type="submit" className='text-white border-2 hover:bg-cyan-500 hover:border-cyan-500 px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
        </form>
      )}
    </div>
  );
};

export default Contact;
